<template>
  <div class="form-container">
    <van-search
      v-model="search_value"
      class="fixed"
      placeholder="搜索表单名称"
      @search="search"
      @input="inputSearch"
      @clear="clearSearch"
    />
    <van-pull-refresh
      v-if="form_list_show.length !== 0 || isRequest"
      v-model="refreshing"
      @refresh="onDownRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="form-list-container">
          <template v-for="(item, index) in form_list_show">
            <div class="form-list" :key="index" @click="selectForm(item)">
              {{ item.name }}
            </div>
          </template>
        </div>
      </van-list>
    </van-pull-refresh>
    <empty-page :msg="emptyMsg" v-else></empty-page>
  </div>
</template>
<script>
import emptyPage from "@/components/emptyPage.vue";
export default {
  name: "addForm",
  components: { emptyPage },
  data() {
    return {
      search_value: "",
      form_list: [],
      form_list_search: [],
      loading: false, //上拉加载
      finished: false, //上拉加载完毕
      refreshing: false, //下拉刷新
      isRequest: true,
      page: 1,
      searchPage: 1,
    };
  },
  created() {},
  computed: {
    form_list_show() {
      return this.search_value ? this.form_list_search : this.form_list;
    },
    emptyMsg() {
      return this.search_value ? "搜索无结果" : "暂无表单";
    },
  },
  methods: {
    // 下拉刷新
    onDownRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.form_list = [];
        this.form_list_search = [];
        this.refreshing = false;
        this.page = 1;
        this.searchPage = 1;
      }
      this.searchFormList();
    },
    // 搜索表单
    searchFormList() {
      let params = {
        page_index: this.search_value ? this.searchPage : this.page,
        page_size: 20,
        search_content: this.search_value,
        state: 2,
      };
      this.loading = true;
      this.isRequest = true;
      this.$ajax("get_form_list", params).then((res) => {
        this.loading = false;
        this.isRequest = false;
        if (res.data && res.data.code === 0) {
          const data = res.data.data;
          if (data.form_list.length) {
            if (this.search_value) {
              this.form_list_search = this.form_list_search.concat(
                data.form_list
              );
            } else {
              this.form_list = this.form_list.concat(data.form_list);
            }
          }
          if (this.form_list_show.length < data.total) {
            this.search_value ? this.searchPage++ : this.page++;
          } else {
            this.finished = true;
          }
        } else {
          this.finished = true;
          this.$toast("获取表单数据失败");
        }
      });
    },
    selectForm(item) {
      this.$store.commit("setFormList", item);
      this.$router.go(-1);
    },
    clearSearch() {
      this.form_list_search = [];
      this.searchPage = 1;
      if (!this.search_value) {
        this.finished = false;
      }
    },
    inputSearch() {
      this.clearSearch();
      if (this.search_value) {
        this.isRequest = true;
      } else {
        this.isRequest = false;
      }
    },
    search() {
      this.clearSearch();
      this.isRequest = true;
      this.searchFormList();
    },
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  .van-search {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
  .form-list-container {
    padding: 0.24rem 0.24rem 0.16rem;
    .form-list {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-top: 0.16rem;
      height: 1.12rem;
      color: #333;
      font-size: 0.32rem;
      background-color: #fff;
      border-radius: 0.16rem;
      padding-left: 0.32rem;
      font-weight: 500;
    }
  }
}
</style>
<style lang="scss">
.form-container {
  .van-list {
    background: #f5f6f9;
  }
}
</style>
